import { Provider } from "react-redux";
import Router from "routing/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "utils/store";
import "./App.scss";

const App = () => (
  <Provider store={store}>
    <div className="App">
      <Router />
    </div>
  </Provider>
);

export default App;
