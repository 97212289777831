import aes from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";

const secretKey = process.env.REACT_APP_API_TOKEN_SECRET_KEY;

const encryptToken = (token) => aes.encrypt(token, secretKey).toString();

const decryptToken = (ciphertext) => {
  const bytes = aes.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(encUtf8);
  return originalText;
};

const LocalStorage = {
  set: {
    accessToken: (accessToken) => {
      window.localStorage.setItem("accessToken", encryptToken(accessToken));
    },
    refreshToken: (refreshToken) => {
      window.localStorage.setItem("refreshToken", encryptToken(refreshToken));
    },
    userDetails: (basicData) => {
      window.localStorage.setItem("userDetails", JSON.stringify(basicData));
    },
    userType: (userType) => {
      window.localStorage.setItem("userType", JSON.stringify(userType));
    },
  },
  get: {
    accessToken: () => {
      try {
        const accessToken = decryptToken(
          window.localStorage.getItem("accessToken"),
        );
        return accessToken;
      } catch (err) {
        return null;
      }
    },
    refreshToken: () => {
      try {
        const refreshToken = decryptToken(
          window.localStorage.getItem("refreshToken"),
        );
        return refreshToken;
      } catch (err) {
        return null;
      }
    },
    userDetails: () => JSON.parse(window.localStorage.getItem("userDetails")),

    userType: () => JSON.parse(window.localStorage.getItem("userType")),
  },
  clear: () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("userDetails");
    window.localStorage.removeItem("userType");
  },
};

export default LocalStorage;
