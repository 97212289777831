import { Spinner } from "react-bootstrap";

import "./style.scss";

const SectionLoading = () => (
  <div className="section-loading">
    <Spinner animation="border" />
  </div>
);

export default SectionLoading;
