import { createSlice } from "@reduxjs/toolkit";

const sidebarInitialState = {
  isOpen: false,
};

export const sidebarStateSlice = createSlice({
  name: "sidebarState",
  initialState: sidebarInitialState,
  reducers: {
    toggleSidebarState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const selectSidebarState = (state) => state.sideBarState.isOpen;

export const { toggleSidebarState } = sidebarStateSlice.actions;

export default sidebarStateSlice.reducer;
