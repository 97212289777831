import { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { AlertTriangle, CheckCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { hideToast, selectToastData } from "utils/store/slice/toastData";

const AppToast = () => {
  const [show, setShow] = useState(false);
  const toastData = useSelector(selectToastData);
  const toastDataDispatch = useDispatch();

  useEffect(() => {
    setShow(toastData.isShowing);
  }, [toastData.isShowing]);

  return (
    <ToastContainer
      className="p-3 position-fixed"
      position="top-end"
      style={{ zIndex: "10000" }}
    >
      <Toast
        bg={toastData.isSuccess ? "success" : "danger"}
        onClose={() => toastDataDispatch(hideToast())}
        show={show}
        delay={2500}
        autohide
      >
        <Toast.Body className="d-flex justify-content-between align-items-center gap-2 text-white fs-5">
          <div className="fw-600 font-satoshi-medium text-start">
            {typeof toastData.message === "string" ? (
              toastData.message
            ) : (
              <div>
                {toastData.message.response} <br />
                {toastData.message.nextStep}
              </div>
            )}
          </div>
          <div>{toastData.isSuccess ? <CheckCircle /> : <AlertTriangle />}</div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default AppToast;
