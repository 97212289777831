import { lazy, Suspense } from "react";

import { useSelector } from "react-redux";

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import AppToast from "components/AppToast";
import NotFoundScreen from "components/NotFoundScreen";
import SectionLoading from "components/SectionLoading";
import UnauthorizedUserScreen from "components/UnauthorizedUserScreen";

import { selectUserData } from "utils/store/slice/userData";

import AuthGuard from "./layout-routes/account-lr/AuthGuard";
import { BulkUploadUpdateTypes } from "../constants";

const Authentication = lazy(() => import("pages/auth/login"));
const MasterList = lazy(() => import("pages/account/masterList"));
const Contests = lazy(() => import("pages/account/components/Contests"));
const ContestMasterList = lazy(() =>
  import("pages/account/masterList/contestMasterList"),
);
const Certification = lazy(() => import("pages/account/certification"));
const ContestCertificates = lazy(() =>
  import("pages/account/certification/contestCertification"),
);
const Feedback = lazy(() => import("pages/account/feedback"));
const ContestFeedback = lazy(() =>
  import("pages/account/feedback/contestFeedback"),
);
const Submissions = lazy(() => import("pages/account/Submissions"));
const ScoreSheet = lazy(() => import("pages/account/scoreSheet"));
const ContestScoreList = lazy(() =>
  import("pages/account/scoreSheet/contestScoreList"),
);
const NonRazorpayUser = lazy(() => import("pages/account/NonRazorpayUser"));
const Report = lazy(() => import("pages/account/report"));
const ReportMenu = lazy(() => import("pages/account/report/reportOptions"));
const ContestEnrollmentHistory = lazy(() =>
  import("pages/account/report/contestEnrollmentLogs"),
);
const UploadSubmissionLogsData = lazy(() =>
  import("pages/account/report/uploadSubmissionLogs"),
);

const FeedbackReports = lazy(() =>
  import("pages/account/report/FeedbackReports"),
);

const ConsolidatedMasterList = lazy(() =>
  import("pages/account/report/ConsolidatedMasterList"),
);

const SubmissionCountReport = lazy(() =>
  import("pages/account/report/SubmissionCountReport"),
);

const BulkUpdateOptionsLandingPage = lazy(() =>
  import("pages/account/bulk-options"),
);
const BulkUpdate = lazy(() => import("pages/account/bulk-options/BulkUpdate"));

const ContentManagementSystem = lazy(() =>
  import("pages/account/contestManagementSystem"),
);
const CMSContests = lazy(() =>
  import("pages/account/contestManagementSystem/components/CMSContests"),
);
const CMSContestDetails = lazy(() =>
  import("pages/account/contestManagementSystem/components/CMSContestDetails"),
);
const ContestManagementForm = lazy(() =>
  import(
    "pages/account/contestManagementSystem/components/ContestManagementForm"
  ),
);

const SchoolManagementPage = lazy(() =>
  import("pages/account/SchoolManagement"),
);

const JudgeManagementPanel = lazy(() =>
  import("pages/account/judgeManagementPanel"),
);

const FeedbackGeneration = lazy(() =>
  import("pages/account/feedbackGeneration"),
);

const Router = () => {
  const { user, token } = useSelector(selectUserData);
  const isAdmin = user?.userType === "admin";
  const isUserLoggedIn = user?.userType && token?.access;
  const roles = {
    admin: "admin",
    judge: "judge",
  };

  const getHomePageUrl = () =>
    isAdmin ? "/account/master-list" : "/account/score-sheet";

  const getRelativePath = () => (isAdmin ? "master-list" : "score-sheet");

  const getPageBasedOnLoginState = () =>
    isUserLoggedIn ? <Navigate to={getHomePageUrl()} /> : <Authentication />;

  return (
    <BrowserRouter>
      <Suspense fallback={<SectionLoading />}>
        <div className="h-100 w-100">
          <AppToast />
          {/* login routes */}
          <Routes>
            <Route path="/" element={<Navigate to="auth" />} />
            <Route path="/auth" element={getPageBasedOnLoginState()} />
            <Route path="/account">
              <Route index element={<Navigate to={getRelativePath()} />} />

              <Route element={<AuthGuard allowedUserTypes={[roles.admin]} />}>
                <Route path="master-list" element={<MasterList />}>
                  <Route index element={<Navigate to="contests" />} />
                  <Route path="contests" element={<Contests />} />
                  <Route
                    path="contests/:contestId"
                    element={<ContestMasterList />}
                  />
                </Route>

                <Route
                  path="contest-management-system"
                  element={<ContentManagementSystem />}
                >
                  <Route index element={<Navigate to="contests" />} />
                  <Route path="contests" element={<CMSContests />} />
                  <Route
                    path="contests/:contestId"
                    element={<CMSContestDetails />}
                  />
                  <Route
                    path="contests/create"
                    element={<ContestManagementForm />}
                  />
                </Route>
                <Route
                  path="school-management-system"
                  element={<SchoolManagementPage />}
                />
                <Route
                  path="judge-management-system"
                  element={<JudgeManagementPanel />}
                />
                <Route path="certification" element={<Certification />}>
                  <Route index element={<Navigate to="contests" />} />
                  <Route path="contests" element={<Contests />} />
                  <Route
                    path="contests/:contestId"
                    element={<ContestCertificates />}
                  />
                </Route>

                <Route path="feedback" element={<Feedback />}>
                  <Route index element={<Navigate to="contests" />} />
                  <Route path="contests" element={<Contests />} />
                  <Route
                    path="contests/:contestId"
                    element={<ContestFeedback />}
                  />
                </Route>

                <Route
                  path="non-razor-pay-user"
                  element={<NonRazorpayUser />}
                />

                <Route path="report" element={<Report />}>
                  <Route index element={<Navigate to="options" />} />
                  <Route path="options" element={<ReportMenu />} />
                  <Route
                    path="options/enrollment-logs"
                    element={<ContestEnrollmentHistory />}
                  />
                  <Route
                    path="options/submission-logs"
                    element={<UploadSubmissionLogsData />}
                  />
                  <Route
                    path="options/feedback-report"
                    element={<FeedbackReports />}
                  />
                  <Route
                    path="options/consolidated-master-list"
                    element={<ConsolidatedMasterList />}
                  />
                  <Route
                    path="options/submission-count-report"
                    element={<SubmissionCountReport />}
                  />
                </Route>
                <Route path="bulk-options" element={<Outlet />}>
                  <Route index element={<BulkUpdateOptionsLandingPage />} />
                  <Route
                    path="update-levels"
                    element={
                      <BulkUpdate updateType={BulkUploadUpdateTypes.LEVEL} />
                    }
                  />
                  <Route
                    path="update-grades"
                    element={
                      <BulkUpdate updateType={BulkUploadUpdateTypes.GRADE} />
                    }
                  />
                  <Route
                    path="update-submissions"
                    element={
                      <BulkUpdate
                        updateType={BulkUploadUpdateTypes.SUBMISSIONS}
                      />
                    }
                  />
                  <Route
                    path="update-tracking-id"
                    element={
                      <BulkUpdate
                        updateType={BulkUploadUpdateTypes.SHIPMENTS}
                      />
                    }
                  />
                  <Route
                    path="update-magazines"
                    element={
                      <BulkUpdate updateType={BulkUploadUpdateTypes.MAGAZINE} />
                    }
                  />
                </Route>
              </Route>

              <Route
                element={
                  <AuthGuard allowedUserTypes={[roles.admin, roles.judge]} />
                }
              >
                <Route path="score-sheet" element={<ScoreSheet />}>
                  <Route index element={<Navigate to="contests" />} />
                  <Route path="contests" element={<Contests />} />
                  <Route
                    path="contests/:contestId"
                    element={<ContestScoreList />}
                  />
                  <Route
                    path="contests/:contestId/feedback-generation/:scoreSheetRowId"
                    element={<FeedbackGeneration />}
                  />
                </Route>

                <Route path="submissions" element={<Submissions />} />
              </Route>
            </Route>
            <Route path="/unauthorized" element={<UnauthorizedUserScreen />} />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </div>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
