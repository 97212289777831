import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { XCircle } from "react-feather";

import {
  selectSidebarState,
  toggleSidebarState,
} from "utils/store/slice/sidebarState";
import useAuth from "utils/customHooks/useAuth";
import ReportLogo from "./logo/Report";
import ScoreSheetLogo from "./logo/ScoreSheet";
import FeedbackLogo from "./logo/Feedback";
import ContestLogo from "./logo/Contest";
import CertificationLogo from "./logo/Certification";

import "./style.scss";
import UserLogo from "./logo/User";
import CMS from "./logo/CMS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faUserGear } from "@fortawesome/free-solid-svg-icons";

const SideNavigation = () => {
  const [sidebarState, setSidebarState] = useState("close");
  const isSidebarOpen = useSelector(selectSidebarState);
  const sidebarStateDispatch = useDispatch();
  const { getUserType } = useAuth();

  const isAdmin = getUserType() === "admin";

  const changeSidebarWidth = () =>
    sidebarStateDispatch(toggleSidebarState(!isSidebarOpen));

  const hideSidebarOnMobile = () => {
    const w = window.innerWidth;
    if (w <= 768) {
      sidebarStateDispatch(toggleSidebarState(false));
    }
  };

  useEffect(
    () => (isSidebarOpen ? setSidebarState("open") : setSidebarState("close")),
    [isSidebarOpen],
  );

  return (
    <div
      className={`sidebar-container ${sidebarState} ${sidebarState}-shadow `}
    >
      <div className="app-logo">
        <NavLink to="/account/master-list/contests">
          {sidebarState === "open" ? (
            <img
              className="full-logo"
              src="/assets/app-logo.svg"
              alt="wizkids carnivals"
            />
          ) : (
            <img
              className="small-logo"
              src="/assets/app-logo-small.png"
              alt="wizkids carnivals"
            />
          )}
        </NavLink>
      </div>
      <div
        className={`link-container d-flex flex-column justify-content-center ${sidebarState}`}
      >
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/master-list/contests"
          >
            <div className="link-content">
              <div className="icon">
                <ContestLogo />
              </div>
              Master List
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/contest-management-system"
          >
            <div className="link-content">
              <div className="icon">
                <CMS />
              </div>
              CMS
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/school-management-system"
          >
            <div className="link-content">
              <div className="icon">
                <FontAwesomeIcon icon={faGraduationCap} />
              </div>
              School
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/judge-management-system"
          >
            <div className="link-content">
              <div className="icon">
                <FontAwesomeIcon icon={faUserGear} />
              </div>
              Judge
            </div>
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/score-sheet/contests"
        >
          <div className="link-content">
            <div className="icon">
              <ScoreSheetLogo />
            </div>
            Score Sheet
          </div>
        </NavLink>

        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/feedback/contests"
          >
            <div className="link-content">
              <div className="icon">
                <FeedbackLogo />
              </div>
              Feedback
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/certification/contests"
          >
            <div className="link-content">
              <div className="icon">
                <CertificationLogo />
              </div>
              Certification
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/non-razor-pay-user"
          >
            <div className="link-content">
              <div className="icon">
                <UserLogo />
              </div>
              Contest Enrollment
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/report"
          >
            <div className="link-content">
              <div className="icon">
                <ReportLogo />
              </div>
              Report
            </div>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link-class" : "")}
            onClick={hideSidebarOnMobile}
            to="/account/bulk-options"
          >
            <div className="link-content">
              <div className="icon">
                {/* TODO: update this logo */}
                <ReportLogo />
              </div>
              Bulk Update
            </div>
          </NavLink>
        )}
      </div>
      {sidebarState === "open" && (
        <button
          onClick={changeSidebarWidth}
          className="toggle-button-mobile d-block d-md-none"
        >
          <XCircle />
        </button>
      )}
    </div>
  );
};

export default SideNavigation;
