import { createSlice } from "@reduxjs/toolkit";

const filterInitialState = {
  date: JSON.stringify(new Date()),
};

export const filterStateSlice = createSlice({
  name: "filterState",
  initialState: filterInitialState,
  reducers: {
    updateDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const selectFilterState = (state) => state.filterState;

export const { updateDate } = filterStateSlice.actions;

export default filterStateSlice.reducer;
