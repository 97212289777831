import { useCallback } from "react";

import { Navigate, useLocation } from "react-router-dom";

import useAuth from "utils/customHooks/useAuth";

import AccountLayout from ".";

const AuthGuard = ({ allowedUserTypes }) => {
  const location = useLocation();
  const { getUserLoginState, getUserType } = useAuth();
  const isUserLoggedIn = getUserLoginState();
  const userType = getUserType();

  const getRedirectPath = useCallback(
    () => location.pathname,
    [location.pathname],
  );

  const showPage = () => (
    allowedUserTypes.includes(userType) ? (
      <AccountLayout />
    ) : (
      <Navigate to="/unauthorized" />
    )
  );

  return isUserLoggedIn ? showPage() : (
    <Navigate
      to="/auth"
      replace
      state={{
        redirectTo: getRedirectPath(),
      }}
    />
  );
};

export default AuthGuard;
