import { useState } from "react";

import { Dropdown } from "react-bootstrap";

import { ChevronDown } from "react-feather";

import { useSelector } from "react-redux";

import useAuth from "utils/customHooks/useAuth";

import { selectUserData } from "utils/store/slice/userData";
import LogoutAlertDialog from "../LogoutAlertDialog";

import "./style.scss";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const { logoutUser } = useAuth();

  const { user } = useSelector(selectUserData);

  return (
    <div className="d-none d-md-flex p-3 ps-md-5 align-items-center">
      <div className="profile-dropdown ms-auto">
        <Dropdown>
          <Dropdown.Toggle className="border-0 shadow-none">
            <span className="d-flex align-items-center blend-bg dark-blue-text">
              <div
                className="profile-pic me-1"
                style={{ backgroundImage: "url(/assets/user-solid.png)" }}
              />
              <div className="text-start ms-3 me-2 profile-data">
                <div className="profile-name">{user?.firstName}</div>
              </div>
              <ChevronDown />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item onClick={() => setShowModal(true)}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <LogoutAlertDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        logoutUser={logoutUser}
      />
    </div>
  );
};

export default Header;
