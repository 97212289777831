import { Link } from "react-router-dom";
import useAuth from "utils/customHooks/useAuth";

const UnauthorizedUserScreen = () => {
  const { getUserType } = useAuth();
  const isAdmin = getUserType() === "admin";

  const getHomePageUrl = () => (isAdmin ? "/account/master-list" : "/account/score-sheet");

  return (
    <div className="min-vw-100 min-vh-100 d-flex gap-4 flex-column align-items-center justify-content-center">
      <div className="display-1 dark-blue-text font-satoshi-medium">
        Unauthorized
      </div>
      <div>You are not authorized to access the page</div>
      <Link to={getHomePageUrl()} className="primary-button link-button">
        Go To Home
      </Link>
    </div>
  );
};

export default UnauthorizedUserScreen;
