import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import sidebarState from "utils/store/slice/sidebarState";
import { selectUserData } from "utils/store/slice/userData";

import Header from "components/Header";
import SideNavigation from "components/SideNavigation";
import MobileHeader from "components/MobileHeader";

import "./style.scss";

const AccountLayout = () => {
  const { user } = useSelector(selectUserData);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="d-flex account-layout">
      <div
        className="side-nav-wrapper align-self-start"
        style={{ zIndex: "1022" }}
      >
        <SideNavigation />
      </div>
      <div
        className={`d-flex flex-column flex-grow-1 ${
          sidebarState ? "open-width" : "close-width"
        }`}
      >
        <div
          className="d-flex justify-content-end position-relative"
          style={{ zIndex: "1021" }}
        >
          <div className="position-absolute h1 d-none d-sm-block admin-panel-heading text-capitalize">
            {user?.userType} Panel
          </div>
          <Header />
          <MobileHeader />
        </div>
        <div className="px-3 px-lg-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
