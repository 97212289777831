import { useState } from "react";

import { Menu, ChevronDown } from "react-feather";

import { useDispatch, useSelector } from "react-redux";

import { Dropdown } from "react-bootstrap";

import {
  selectSidebarState,
  toggleSidebarState,
} from "utils/store/slice/sidebarState";
import LogoutAlertDialog from "../LogoutAlertDialog";

import useAuth from "../../utils/customHooks/useAuth";

import { selectUserData } from "../../utils/store/slice/userData";

import "./style.scss";

const MobileHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useSelector(selectUserData);
  const isSidebarOpen = useSelector(selectSidebarState);
  const sidebarStateDispatch = useDispatch();
  const { logoutUser } = useAuth();

  const openSidebar = () => (isSidebarOpen
    ? sidebarStateDispatch(toggleSidebarState(false))
    : sidebarStateDispatch(toggleSidebarState(true)));

  return (
    <div className="w-100 d-flex d-md-none px-2 py-3 mobile-header">
      <button onClick={openSidebar} className="menu-button notification-button">
        <Menu />
      </button>
      <div className="profile-dropdown m-0 ms-auto">
        <Dropdown>
          <Dropdown.Toggle className="border-0 shadow-none">
            <span className="d-flex align-items-center bg-light dark-blue-text">
              <div
                className="profile-pic"
                style={{ backgroundImage: " url(/assets/user-solid.png) " }}
              >
              </div>
              <div className="text-start ms-3 me-2 profile-data">
                <div className="profile-name">{user?.firstName}</div>
              </div>
              <ChevronDown />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item onClick={() => setShowModal(true)}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <LogoutAlertDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        logoutUser={logoutUser}
      />
    </div>
  );
};

export default MobileHeader;
