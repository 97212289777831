import { createSlice } from "@reduxjs/toolkit";
import LocalStorage from "services/LocalStorage";

const initialData = {
  user: {
    firstName: LocalStorage.get.userDetails()?.firstName || "",
    lastName: LocalStorage.get.userDetails()?.lastName || "",
    userType: LocalStorage.get.userType() || "",
  },
  token: {
    access: LocalStorage.get.accessToken() || "",
  },
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState: initialData,
  reducers: {
    setUserData: (state, action) => {
      state.user.firstName = action.payload.first_name;
      state.user.lastName = action.payload.last_name;
      state.user.userType = action.payload.user_type;
      state.token.access = action.payload.access;
    },
    resetUserData: (state) => {
      state.user.firstName = "";
      state.user.lastName = "";
      state.user.userType = "";
      state.token.access = "";
    },
  },
});

export const selectUserData = (state) => state.userData;

export const { setUserData, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
